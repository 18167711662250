import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const ICON_ID = 'medal';

const namespace = 'ui-pdp-icon ui-pdp-icon--medal';

const IconMedal = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="medal.svg" alt="" />
  </div>
);

IconMedal.propTypes = {
  className: string,
};

IconMedal.defaultProps = {
  className: null,
};

IconMedal.ICON_ID = ICON_ID;

export default React.memo(IconMedal);
export { IconMedal };
